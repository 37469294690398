<template>
  <div class="mb-6">
    <v-row justify="space-around" class="mb-4 px-4">
      <v-col align-self="center" align="center">
        <v-btn outlined fab small color="light-blue" @click="stopTrack">
          <v-icon>mdi-stop</v-icon>
        </v-btn></v-col
      >
      <v-col align-self="center" align="center">
        <v-btn outlined fab color="light-blue" @click="playTrack" :disabled="isTheSameObject">
          <v-icon large>mdi-play</v-icon>
        </v-btn></v-col
      >
      <v-col align-self="center" align="center">
        <v-btn outlined fab small color="light-blue" @click="pauseTrack">
          <v-icon>mdi-pause</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <div id="fader" class="text-center" v-if="paused">Paused</div> -->
    <div class="d-flex">
      <div class="ml-8">{{ trackInfo.seek | minutes }}/{{ trackInfo.duration | minutes }}</div>
      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            fab
            small
            class="d-inline-flex mr-10 mt-n1"
            color="light-blue"
            v-bind="attrs"
            v-on="on"
            :disabled="seekDisabled"
          >
            <v-icon>mdi-play-speed</v-icon>
          </v-btn>
        </template>
        <v-sheet class="d-flex flex-column px-2 py-1">
          <div
            v-for="speed in speeds"
            class="mx-auto"
            style="cursor: pointer; width: 100%; text-align: center"
            :key="speed"
            :class="{ 'font-weight-bold': speed === playbackSpeed }"
            @click="() => $emit('update-speed', speed)"
          >
            {{ speed }}
          </div>
        </v-sheet>
      </v-menu>
    </div>
    <v-row justify="center">
      <v-col align-self="end" align="center" cols="10">
        <v-slider
          class="text-center"
          ref="message-progress"
          height="20"
          step=".005"
          :value="progressTimesOneHundred"
          :disabled="seekDisabled"
          @start="() => setDragging(true)"
          @end="() => setDragging(false)"
          @change="updateSeek"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
    },
    trackInfo: {
      type: Object,
    },
    isTheSameObject: {
      type: Boolean,
    },
    seekDisabled: {
      type: Boolean,
    },
    playbackSpeed: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isDragging: false,
      frozenProgress: null,
      speeds: [0.75, 1, 1.25, 1.5, 1.75, 2],
    };
  },
  computed: {
    progressTimesOneHundred() {
      return (this.isDragging ? this.frozenProgress : this.progress) * 100;
    },
  },
  methods: {
    setDragging(status) {
      this.isDragging = status;
      this.frozenProgress = this.progress;
    },
    playTrack() {
      this.$emit("playtrack");
    },
    pauseTrack() {
      this.$emit("pausetrack");
    },
    stopTrack() {
      this.$emit("stoptrack");
    },
    updateSeek(percent) {
      this.$emit("updateseek", percent);
    },
  },
};
</script>

<style lang="scss" scoped>
#fader {
  animation: fade 3s ease-in-out infinite;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
