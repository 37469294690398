<template>
  <v-card elevation="0" max-width="600" class="mx-auto my-10">
    <v-row justify="center">
      <v-col>
        <v-img
          contain
          height="400"
          :src="imageSrc"
          :alt="selectedMessageChosen.title"
        ></v-img>
      </v-col>
      <v-col align="center" align-self="center">
        <div class="mb-0 pb-0" id="title">{{ selectedMessageChosen.title }}</div>
        <v-card-subtitle class="mt-0 pa-0">
          by
          {{ selectedMessageChosen.author }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <player-control-bars
      :progress="progress"
      :track-info="getTrackInfo"
      :is-the-same-object="playDisabled"
      :seek-disabled="differentMessageIsLoaded"
      :playbackSpeed="playbackSpeed"
      @update-speed="(playbackSpeed = $event)"
      @updateseek="setSeek"
      @playtrack="play"
      @pausetrack="pause"
      @stoptrack="stop"
    />
    <v-row class="mx-4" justify="center">
      <!-- <iframe
        title="vimeo-player"
        :src="videoLink"
        max-width="640"
        max-height="360"
        frameborder="0"
        allowfullscreen
      ></iframe> -->
      <v-col :cols="isMobile ? '12' : '10'" align="center" align-self="center">
        <p v-html="selectedMessageChosen.content" :style="justifyContentText"></p>
        <v-divider inset></v-divider>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PlayerControlBars from "./PlayerControlBars.vue";

let setSeekInterval;

export default {
  name: "WeeklyMessage",
  watch: {
    playing(playing) {
      if (playing && this.audioObject) {
        setSeekInterval = setInterval(() => {
          this.actuallySetSeek();
          this.saveProgressLocally();
        }, 750);
      } else {
        clearInterval(setSeekInterval);
      }
    },
    selectedMessageChosen(audio) {
      const audioEl = document.getElementById("audio");
      if (this.playing && audioEl.src && audio?.link?.[0]?.link !== audioEl?.src) {
        this.differentMessageIsLoaded = true;
        clearInterval(setSeekInterval);
        // this.$store.dispatch("audio/setAudioSeek", 0);
      } else if (this.playing) {
        this.differentMessageIsLoaded = false;
        setSeekInterval = setInterval(() => {
          this.actuallySetSeek();
        }, 750);
      }
    },
    playbackSpeed(speed) {
      const audioEl = document.getElementById("audio");
      audioEl.playbackRate = speed;
    },
  },
  data() {
    return {
      differentMessageIsLoaded: false,
      playbackSpeed: 1,
    };
  },
  components: { PlayerControlBars },
  props: {
    message: {
      type: [Object, Array],
    },
    selectedMessage: {
      type: [Object, Array],
    },
  },
  metaInfo() {
    return {
      title: `${this.selectedMessageChosen?.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `GraceWay featured sermon by ${this.selectedMessageChosen.author}, titled: ${this.selectedMessageChosen.title}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `GraceWay featured sermon by ${this.selectedMessageChosen.author}, titled: ${this.selectedMessageChosen.title}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${this.selectedMessageChosen?.title}`,
        },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://gracewayradio.com/featured-speakers/${this.selectedMessageChosen.slug}`,
        },
      ],
    };
  },
  computed: {
    // // for resurrection day videos
    // videoLink() {
    //   return this.selectedMessageChosen?.link?.[0]?.link;
    // },
    justifyContentText() {
      return { textAlign: "justify", textIndent: "20px" };
    },
    imageSrc() {
      return this.selectedMessageChosen.image === null
        ? this.selectedMessageChosen?.picture_url
        : this.selectedMessageChosen?.image;
    },
    getTrackInfo() {
      const seek = this.differentMessageIsLoaded ? 0 : this.seek;
      const duration = this.differentMessageIsLoaded ? 0 : this.audioObject?.duration;

      return {
        seek,
        duration,
      };
    },
    playDisabled() {
      const audioEl = document.getElementById("audio");
      return this.playing && this.selectedMessageChosen?.link?.[0]?.link === audioEl?.src;
    },
    seek() {
      return this.$store.state.audio.audioSeek;
    },
    playing() {
      return this.$store.state.audio.audioIsPlaying;
    },
    audioObject() {
      return this.$store.state.audio.audioObject;
    },
    progress: {
      get() {
        if (
          this.differentMessageIsLoaded ||
          !this.audioObject?.hasOwnProperty("duration") ||
          this.audioObject?.duration === 0
          // typeof this.audioObject?.duration === "function" &&
          // this.audioObject?.duration() === 0
        )
          return 0;
        // return this.seek / this.audioObject?.duration?.();
        return this.seek / this.audioObject?.duration;
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    selectedMessageChosen() {
      return this.selectedMessage || this.message;
    },
    currentMessage() {
      // const { messageSlug } = this.$route.params;
      if (this.selectedMessage) {
        return this.selectedMessage?.messageSlug;
      }
      return this.message?.messageSlug;
    },
  },
  methods: {
    actuallySetSeek(newTime) {
      // this.$store.dispatch("audio/setAudioSeek", this.audioObject?.seek());
      this.$store.dispatch("audio/setAudioSeek", newTime);
    },
    saveProgressLocally() {
      const audioEl = document.getElementById("audio");
      const { slug } = this.selectedMessageChosen;

      let progress = audioEl?.currentTime || 0;

      if ((audioEl.duration && audioEl.duration - progress < 20) || progress < 20) {
        progress = 0;
      }

      const sermonTimes = this.$store.state.sermonTimes;
      const { [slug]: currentMessage, ...filteredSermonTimes } = sermonTimes;
      const updatedSermonTimes = {
        ...filteredSermonTimes,
        ...(progress && { [slug]: progress }),
      };
      this.$store.dispatch("saveSermonTimes", updatedSermonTimes);
    },
    setSeek(percent) {
      if (this.playing && this.audioObject) {
        // this.audioObject?.seek((this.audioObject?.duration / 100) * percent);
        const newTime = (this.audioObject?.duration / 100) * percent;
        this.actuallySetSeek(newTime);
      }
    },
    play() {
      if ((!this.audioObject || this.differentMessageIsLoaded) && this.playing) {
        this.stop();
        this.play();
      }
      if (!this.playing || !this.audioObject) {
        this.differentMessageIsLoaded = false;
        const { slug } = this.selectedMessageChosen;
        const existingProgress = this.$store.state.sermonTimes?.[slug];
        console.log("existingProgress", existingProgress);
        this.$store.dispatch("audio/audioPlay", {
          src: this.selectedMessageChosen?.link?.[0]?.link,
          seek: existingProgress || 0,
        });
        // .then(() =>
        //   this.$store.dispatch("analytics/updateListeningLog", {
        //     action: "play",
        //     message: { title: this.selectedMessageChosen.title },
        //   })
        // );
      }
    },
    pause() {
      this.$store.dispatch("audio/audioPause");
      // .then(() =>
      //   this.$store.dispatch("analytics/updateListeningLog", {
      //     action: "pause",
      //     message: { title: this.selectedMessageChosen.title },
      //   })
      // );
    },
    stop() {
      this.$store.dispatch("audio/audioStop");
      // .then(() =>
      //   this.$store.dispatch("analytics/updateListeningLog", {
      //     action: "pause",
      //     message: { title: this.selectedMessageChosen.title },
      //   })
      // );
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     if (!this.$store.state.audio.messageAudio) {
  //       this.$store.dispatch("audio/createAudio", {
  //         audio: this.selectedMessageChosen?.link?.[0]?.link,
  //         location: "SET_AUDIO_OBJECT",
  //       });
  //     }
  //   });
  // },
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
#title {
  font-family: "Satisfy", cursive;
  font-weight: bold;
  font-size: 200%;
  color: rgba(9, 44, 110, 0.8);
  text-shadow: 1px 4px 6px #def, 0 0 0 #000, 1px 4px 6px #def;
}
</style>
